import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import myUpreach from "../../images/myupreach.svg";
import upreach from "../../images/pageImages/ApplyNow/myupreach.jpg";
import ReactMarkdown from "react-markdown";

const StyledWrapper = styled.div`
  background-color: white;
  margin-top: 100px;

  .myupreach-login {
    font-size: 32px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 43px;
    color: black;
    @media (max-width: 600px) {
      font-size: 15px;
    }
  }

  .link-upreach {
    text-decoration: underline;
    margin: 0;
  }

  .myupreach-list li {
    line-height: 40px;

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }

  .myupreach-list-img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }

  .myupreach-logo {
    @media (max-width: 600px) {
      width: 220px;
      margin: auto;
      display: block;
    }
  }
`;

const MyUpreach = ({ data, cta }) => {
  const mainUrl = process.env.GATSBY_API_URL;
  return (
    <StyledWrapper>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-lg-6 padding-left-8 padding-right-8 order-2 order-lg-1">
            {data && data.upreachLogo && (
              <img
                className="img-fluid my-4 p-2 myupreach-logo"
                src={mainUrl + data.upreachLogo.url}
                alt=""
              />
            )}
            {data && (
              <ReactMarkdown className="myupreach-list">
                {data.Summary}
              </ReactMarkdown>
            )}
            {cta && (
              <Link className="myupreach-login" to={cta.link} target="_blank">
                <p className="link-upreach">{cta.title}</p>
              </Link>
            )}
          </div>
          <div className="col-12 col-lg-6 order-1 order-lg-2">
            {data && data.Image && (
              <img
                className="img-fluid myupreach-list-img"
                src={mainUrl + data.Image.url}
                alt=""
              />
            )}
          </div>
        </div>
      </div>
    </StyledWrapper>
  );
};

export default MyUpreach;
